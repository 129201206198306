// extracted by mini-css-extract-plugin
export const Post = "Article-module--Post--1avV7";
export const MediaImage = "Article-module--MediaImage--3hwh1";
export const Message = "Article-module--Message--2kvZn";
export const ArticleBody = "Article-module--ArticleBody--1F_hl";
export const AuthorDisplay = "Article-module--AuthorDisplay--COiwB";
export const InPostListing = "Article-module--InPostListing--1xnjt";
export const HighlightedMessage = "Article-module--HighlightedMessage--1Gibt";
export const PostTags = "Article-module--PostTags--2RHni";
export const PostMeta = "Article-module--PostMeta--13XTG";
export const NoEffectMessage = "Article-module--NoEffectMessage--1X5OZ";
export const ArticleHeading = "Article-module--ArticleHeading--2jtls";
export const PostLink = "Article-module--PostLink--2n-Hi";
export const TimeDisplay = "Article-module--TimeDisplay--1kXOa";
export const MessageMeta = "Article-module--MessageMeta--3-irg";
export const ArticlePage = "Article-module--ArticlePage--3Azy3";
export const ArticleIngress = "Article-module--ArticleIngress--2x1pX";
export const ArticleExcerpt = "Article-module--ArticleExcerpt--kB-5O";